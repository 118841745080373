import React, { useEffect, useRef, useState, useCallback } from "react";
import locations from "../locations/trout_texas.json";
import { createGlobalStyle } from "styled-components"; // JSON-файл с данными о локациях

const containerStyle = {
  width: "100%",
  height: "500px",
};

const center = {
  lat: 37.0902, // Центр карты (пример для США)
  lng: -95.7129,
};

const GlobalStyle = createGlobalStyle`
  .gm-style-iw-d h4 {
    font-size: 18px;
    margin-bottom: 16px;
  }
  
  .gm-style-iw-d p {
    margin: 0 0 8px;
  }
`;

const MapComponent = ({ search, total }) => {
  const mapRef = useRef(null); // Ссылка на карту
  const markersRef = useRef([]); // Ссылки на активные маркеры
  const [map, setMap] = useState(null); // Хранение экземпляра карты

  useEffect(() => {
    // Создаем карту только при первом монтировании
    if (!window.google) {
      console.error("Google Maps API is not loaded.");
      return;
    }

    const mapInstance = new window.google.maps.Map(mapRef.current, {
      center,
      zoom: 4,
    });

    setMap(mapInstance); // Сохраняем экземпляр карты
  }, []);

  const updateMarkers = useCallback(() => {
    if (!map || !window.google) return;

    // Очистка старых маркеров
    markersRef.current.forEach((marker) => marker.setMap(null));
    markersRef.current = [];

    const geocoder = new window.google.maps.Geocoder();
    const infoWindow = new window.google.maps.InfoWindow();

    // Создание новых маркеров на основе фильтра
    locations
      .filter((item) =>
        `${item.city} ${item.location}`.toLowerCase().includes(search.toLowerCase())
      )
      .filter((item) => total ? Number(item.total) > Number(total) : item)
      .forEach((location) => {
        geocoder.geocode(
          { address: `USA, Texas, ${location.city}, ${location.location}` },
          (results, status) => {
            if (status === "OK" && results[0]) {
              const marker = new window.google.maps.Marker({
                position: results[0].geometry.location,
                map,
                title: location.location,
              });

              // Добавляем маркер в массив
              markersRef.current.push(marker);

              // Обработчик клика для маркера
              marker.addListener("click", () => {
                const contentString = `
                  <div>
                    <h4>${location.location}</h4>
                    <p><strong>Dates:</strong> ${location.dates}</p>
                    <p><strong>Address:</strong> ${location.city}, ${location.location}</p>
                    <p><strong>Total:</strong> ${location.total || ""}</p>
                    <p><strong>Hatchery:</strong> ${location.hatchery || ""}</p>
                  </div>
                `;
                infoWindow.setContent(contentString);
                infoWindow.open(map, marker);
              });
            } else {
              console.error(
                `Geocode was not successful for address: ${location.address}. Status: ${status}`
              );
            }
          }
        );
      });
  }, [map, search, total]);

  useEffect(() => {
    updateMarkers(); // Обновляем маркеры при изменении фильтра
  }, [search, updateMarkers]);

  return (
    <>
      <GlobalStyle />
      <div ref={mapRef} style={containerStyle}></div>
    </>
  );
};

export default MapComponent;
