import React, {useState} from "react";
import MapComponent from "./Components/MapComponent";
import 'bootstrap/dist/css/bootstrap.min.css';
import {Col, Container, Row} from 'react-bootstrap';
import { createGlobalStyle } from 'styled-components';
import { Form } from 'react-bootstrap';

const GlobalStyle = createGlobalStyle`
  h1 {
    font-size: 24px;
    margin: 16px 0;
    font-weight: 700;
  }
  
  .form-label {
    font-weight: 600;
  }
`;

const App = () => {
  const [search, setSearch] = useState('');
  const [total, setTotal] = useState(0);

  return (
    <Container>
      <GlobalStyle />
      <Row>
        <Col xs={12} md={10}>
          <h1>Trout Stocking Schedule</h1>
        </Col>
      </Row>
      <Row>
      <Col xs={12} md={10}>
          <MapComponent search={search} total={total} />
        </Col>

        <Col xs={12} md={2}>
          <Form.Label>Filter by location</Form.Label>

          <Form.Control
            type="text"
            placeholder="Type location..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className="mb-4"
          />

          <Form.Label className="mb-2">Filter by count</Form.Label>

          <Form.Check
            type="radio"
            label="All"
            checked={total === 0}
            onChange={() => setTotal(0)}
          />

          <Form.Check
            type="radio"
            label="More than 500"
            checked={total === 500}
            onChange={() => setTotal(500)}
          />

          <Form.Check
            type="radio"
            label="More than 1000"
            checked={total === 1000}
            onChange={() => setTotal(1000)}
          />

          <Form.Check
            type="radio"
            label="More than 2000"
            checked={total === 2000}
            onChange={() => setTotal(2000)}
          />

          <Form.Check
            type="radio"
            label="More than 5000"
            checked={total === 5000}
            onChange={() => setTotal(5000)}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default App;